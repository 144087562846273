var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('static-fullscreen-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.data.address)+" ("+_vm._s(_vm.data.id)+")")]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showRoomDialog = true}}},[_vm._v("Добавить студию")]),_c('v-btn',{attrs:{"color":"primary","title":"Редактировать элемент"},on:{"click":function($event){_vm.showEditDialog = true}}},[_c('btn-title',{attrs:{"icon":"far fa-edit"}},[_vm._v("Редактировать")])],1)]},proxy:true}])},[_c('portal',{attrs:{"to":"v-main"}},[_c('edit-dialog',{attrs:{"id":_vm.id,"api":_vm.url},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}}),_c('room-dialog',{attrs:{"object_id":_vm.id,"id":0},model:{value:(_vm.showRoomDialog),callback:function ($$v) {_vm.showRoomDialog=$$v},expression:"showRoomDialog"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',[_vm._v("фото объекта")])]},proxy:true}])},[_c('a-view-images',{attrs:{"value":_vm.data.images}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',[_vm._v("основная информация")])]},proxy:true}])},[_c('a-form-view',{attrs:{"value":_vm.data,"model":[_vm.model[0], _vm.model[1], _vm.model[2], _vm.model[3], _vm.model[4], _vm.model[11], _vm.model[12], {
            name: 'finance_status',
            title: 'Финансовый статус',
            type: 'number',
          }]}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"warning"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-tabs',{attrs:{"background-color":"transparent","slider-color":"white"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab,i){return _c('v-tab',{key:i,staticClass:"mr-3"},[_vm._v(_vm._s(tab.title))])}),1)]},proxy:true}])},[_c('v-tabs-items',{staticClass:"transparent",staticStyle:{"min-height":"200px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('a-table-f-api',{ref:"tableRoom",attrs:{"api":"/kazan/rooms","model":_vm.$store.getters['config/get'].models.rooms.list,"searchable":false,"defaults":{
                  filters: { object_id: _vm.id },
                  sort: { key: 'id', order: 'DESC' },
                }},on:{"click":function($event){return _vm.$router.push({
                    name: 'rooms_view',
                    params: { id: $event.row.id },
                  })}}})],1)],1),_c('v-tab-item',[_c('v-card-text',{staticStyle:{"height":"300px","overflow":"auto"}},[_c('a-view-files',{attrs:{"value":_vm.data.files}})],1)],1),_c('v-tab-item',[_c('v-card-text',{staticStyle:{"height":"300px","overflow":"auto"}},[_c('a-table-f-api',{ref:"tableFinance",attrs:{"api":"/kazan/finance","model":_vm.$store.getters['config/get'].models.finance.list,"searchable":false,"defaults":{
                  filters: { object_id: _vm.id },
                  sort: { key: 'id', order: 'DESC' },
                }}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }