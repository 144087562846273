<template>
  <static-fullscreen-card>
    <template v-slot:title>{{ data.address }} ({{ data.id }})</template>
    <template v-slot:actions>
      <v-btn color="primary" @click="showRoomDialog = true"
        >Добавить студию</v-btn
      >
       <!-- <v-btn color="primary" @click="clickSell();"
        >Продать</v-btn
      > -->
      <v-btn
        color="primary"
        @click="showEditDialog = true"
        title="Редактировать элемент"
        ><btn-title icon="far fa-edit">Редактировать</btn-title></v-btn
      ></template
    >
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id" :api="url"></edit-dialog>
      <room-dialog
        v-model="showRoomDialog"
        :object_id="id"
        :id="0"
      ></room-dialog>
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading>
            <div>фото объекта</div>
          </template>
          <a-view-images :value="data.images" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading>
            <div>основная информация</div>
          </template>
          <a-form-view
            :value="data"
            :model="[model[0], model[1], model[2], model[3], model[4], model[11], model[12], {
              name: 'finance_status',
              title: 'Финансовый статус',
              type: 'number',
            }]"
          />
        </material-card>
      </v-col>
      <v-col cols="12">
        <material-card color="warning">
          <template v-slot:heading>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              slider-color="white"
            >
              <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{
                tab.title
              }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items
            v-model="tab"
            class="transparent"
            style="min-height: 200px"
          >
            <v-tab-item>
              <v-card-text style="height: 300px">
                <a-table-f-api
                  ref="tableRoom"
                  api="/kazan/rooms"
                  :model="$store.getters['config/get'].models.rooms.list"
                  :searchable="false"
                  :defaults="{
                    filters: { object_id: id },
                    sort: { key: 'id', order: 'DESC' },
                  }"
                  @click="
                    $router.push({
                      name: 'rooms_view',
                      params: { id: $event.row.id },
                    })
                  "
                />
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text style="height: 300px; overflow: auto">
                <a-view-files :value="data.files" />
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text style="height: 300px; overflow: auto">
                <a-table-f-api
                  ref="tableFinance"
                  api="/kazan/finance"
                  :model="$store.getters['config/get'].models.finance.list"
                  :searchable="false"
                  :defaults="{
                    filters: { object_id: id },
                    sort: { key: 'id', order: 'DESC' },
                  }"
                />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./dialogs/objectDialog"),
    RoomDialog: () => import("./dialogs/roomDialog"),
  },
  data() {
    return {
      id: 0,
      showRoomDialog: false,
      showEditDialog: false,
      title: "",
      data: {},
      model: this.$store.getters["config/get"].models.objects.form,
      url: "/kazan/objects",
      tab: 0,
      tabs: [{ title: "студии" }, { title: "проектная документация" }, { title: "Финансы" }],
    };
  },
  created() {
    this.$root.title = "Объекты";
    this.id = Number(this.$route.params.id);
    this.fitchData();
  },
  watch: {
    showRoomDialog() {
      if (!this.showRoomDialog) {
        this.$refs.tableRoom.updateData();
      }
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    clickSell() {

    },
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
    },
  },
};
</script>
